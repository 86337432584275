<template>
  <div class="pass-form">
    <form @submit.prevent="validateEmail">
      <h5>
        Welcome to MDME!
      </h5>
      <h5>
        Before you use this website, you MUST validate your email.
      </h5>
      <h5>
        If you do not, you will not be able to recover your account, or
        potentially receive important emails. You should receive a validation
        code shortly in your inbox to complete this one time process.
      </h5>
      <div class="form-group">
        <input
          placeholder="Verification Code"
          type="number"
          required
          autocomplete="new-password"
          class="form-control form-control-lg"
          v-model="form.code"
        />
      </div>
      <button
        :disabled="loading"
        type="submit"
        class="btn-blue"
        @click.stop.prevent="validateEmail"
      >
        <template v-if="!loading">
          Verify E-Mail
        </template>
        <template v-else>
          <b-spinner type="grow"></b-spinner>
          Processing...
        </template>
      </button>
    </form>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      confirmed: false,
      form: {
        code: "",
      },
      loading: false,
    };
  },
  created() {
    if (!this.getUser) {
      this.$router.push({ name: "Login" }).catch(() => {});
      return;
    } else if (
      this.getUser.attributes &&
      this.getUser.attributes.email_verified
    ) {
      this.$router
        .push(
          this.$route && this.$route.query && this.$route.query.to
            ? this.$route.query.to
            : "/"
        )
        .catch(() => {});
    }
  },
  async mounted() {
    if (this.getUserMustVerifyEmail) {
      await Auth.verifyCurrentUserAttribute("email");
    }
  },
  computed: {
    ...mapGetters(["getUser", "getUserMustVerifyEmail"]),
  },
  methods: {
    ...mapActions(["fetchUser"]),
    async validateEmail() {
      this.loading = true;
      var self = this;
      try {
        Auth.verifyCurrentUserAttributeSubmit("email", this.form.code)
          .then(async () => {
            // clear out user info so it gets set in fetchUser
            self.$store.commit("setUserInfo", null);
            await self.fetchUser;
            self.loading = false;
            self.$store.dispatch(
              "createAlerts",
              "Your email has been verified"
            );
            self.$router.push("/").catch(() => {});
          })
          .catch((err) => {
            if (err.message) {
              return self.$store.dispatch("createErrors", err.message);
            } else {
              return self.$store.dispatch(
                "createErrors",
                "There was an issue verifying your password. If this continues, please refresh the page to get a new code."
              );
            }
          });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
